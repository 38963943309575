import React from 'react';
import {Helmet} from "react-helmet";
import profile from '../../assets/images/profile.png';

const SEO = ({ title, description }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{ title } | Israel Anibal Valadez</title>
            <meta name="description" content={ description } />
        
            <link rel="canonical" href="https://israelanibal.com" />

            <meta property="og:title" content={ title } />
            <meta property="og:description" content={ description } />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://israelanibal.com" />
            <meta property="og:image" content={ profile } />
        </Helmet>
    )
}

export default SEO
