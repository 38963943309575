import React from 'react';
import { Container, Grid, Box } from '@material-ui/core';
import Styles from './Cases.module.scss';
import dog from '../../assets/images/dog.png';
import revap from '../../assets/images/revap.png';
import envibe from '../../assets/images/envibe.png';
import cof from '../../assets/images/cof.png';
import bancoppel from '../../assets/images/bancoppel.png';

const Cases = () => {
  return (
    <Container maxWidth="md">
        <Box mt="40px"></Box>
        <Grid container spacing={4} justifyContent="center">
            <Grid item lg={5} md={5} sm={10} xs={10}>
                <a className={Styles.image}
                    href='https://drive.google.com/file/d/1puBXWEicEYqaROCC1_DtujlbnYONV6JH/view?usp=sharing'
                    rel="noreferrer" 
                    target="_blank" 
                    title="See My Dog App case"
                >
                    <img src={dog} alt="My Dog App A case study for Google Certificate Program" />
                </a>
               
            </Grid>
            <Grid item lg={5} md={5} sm={10} xs={10}>
                <a className={Styles.image}
                    href='https://drive.google.com/file/d/16ZfllQgfLl2AxwzNyEc6yFGW3IfDFKGu/view?usp=sharing'
                    rel="noreferrer" 
                    target="_blank" 
                    title="See RevApp case"
                >
                    <img src={revap} alt="RevApp Website A case study for Google Certificate Program" />
                </a>
                
            </Grid>
            <Grid item lg={5} md={5} sm={10} xs={10}>
                <a className={Styles.image}
                    href='https://drive.google.com/file/d/1Fos4vmnKQeofjYe2eueHeESCOnsLFMRp/view?usp=sharing'
                    rel="noreferrer" 
                    target="_blank" 
                    title="See Envibe Studios case"
                >
                    <img src={envibe} alt="Envibe Studios Website A dev team website case study" />
                </a>
                
            </Grid>
            <Grid item lg={5} md={5} sm={10} xs={10}>
                <a className={Styles.image}
                    href='https://drive.google.com/file/d/1fpDs6znMrJI6oX-H3qm8fsWElsddv8Wb/view?usp=sharing'
                    rel="noreferrer" 
                    target="_blank" 
                    title="See City of Festivals case"
                >
                    <img src={cof} alt="City of Festivals Platform Event planning platform from The City of Sacramento" />
                </a>
                
            </Grid>
            <Grid item lg={5} md={5} sm={10} xs={10}>
                <a className={Styles.image}
                    href='https://drive.google.com/file/d/1mxYKor-RHSu7U56AYq0rDodJMyizaG8a/view?usp=sharing'
                    rel="noreferrer" 
                    target="_blank" 
                    title="See BanCoppel case"
                >
                    <img src={bancoppel} alt="BanCoppel Banking App A mobile banking case study" />
                </a>
                
            </Grid>
        </Grid>
    </Container>
  )
}

export default Cases