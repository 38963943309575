import React from 'react';
import Me from './Me';
import SEO from '../Helmet/Helmet';
import { Container, Grid } from '@material-ui/core';

const Home = () => {
    return (
        <>
            <SEO
                title="Senior UX/UI Designer working remotely"
                description="The place to find your design partner for your digital projects. Research, Wireframes, Mockups and Prototypes."
            />
            <Container maxWidth="sm">
                <Grid container spacing={4} justifyContent="center">
                        <Me />
                </Grid>
            </Container>
        </>
    )
}

export default Home
