import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {Home, Cases} from './components/Index';
import { createTheme, ThemeProvider } from '@material-ui/core';

import './css/App.scss';

const theme = createTheme({
    palette: {
      primary: {
        light: '#5C61F3',
        main: '#4C51DB',
        dark: '#33379A',
        contrastText: '#fff',
      }
    }
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
          <Router>
          <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/cases" component={Cases} />
            </Switch>
          </Router>
        </ThemeProvider>
    )
}

export default App
