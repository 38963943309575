import React from 'react';
import { Grid } from '@material-ui/core';

import Styles from './Me.module.scss';
import standby from '../../assets/images/standby.png';

const Me = () => {
    return (
        <Grid item lg={7} md={7} sm={7} xs={8}>
            <img loading="lazy" src={standby} alt="UX Design Projects + Avatar" className={Styles.image} />
            <h1 className={Styles.h1}>Israel Anibal</h1>
            <h2 className={Styles.h2}>UX/UI Designer</h2>
            <p className={Styles.paragraph}><strong>I love creating things.</strong> <br />Novelty things, fun things, things that matter and bring something positive to the world.</p>
            
            <a  
                className={Styles.resume}
                href="https://drive.google.com/file/d/1n-RXqG7vVhAW4uIaPbmzaPYSXhc_G4Lu/view?usp=sharing" 
                rel="noreferrer" 
                target="_blank" 
                title="Download my Resume">
                Download my Resume
            </a>
            <a href="/cases" className={Styles.resume}>Case Studies</a>
            <div align="center">
                <a 
                    className="linkedin"
                    href="https://www.linkedin.com/in/ianibal/" 
                    rel="noreferrer" 
                    target="_blank" 
                    title="Connect with me on Linkedin">
                </a>
                <a 
                    className="behance"
                    href="https://www.behance.net/ianibal" 
                    rel="noreferrer" 
                    target="_blank" 
                    title="More design projects on Behance">
                </a>
                <a 
                    className="mail"
                    href="mailto:info@israelanibal.com"
                    title="Send me an email">
                </a>
            </div>
        </Grid>
    )
}

export default Me
